<template>
  <div>
    <vs-row v-if="loadPayments && product.productOption.includes('pay-now') && (cardEnabled || product.eftEnabled)" class="mb-8">
      <vs-col vs-w="12" class="flex items-center ml-4">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <img class="icon-control" :src="productLight" alt="product">
        </div>
        <h5 class="ml-5 mb-0">Payments</h5>
      </vs-col>
      <hr class="line-hr mb-8 mt-2 w-full ml-4" />

      <vs-col vs-w="12" class="onboarding-form">
        <div class="flex flex-wrap">
          <div class="md:col-start-3 md:col-end-11 bg-container-80">
            <p class="text-body mb-10">Available payment methods and corresponding transaction fees are listed below.</p>
            <div class="flex justify-between">
              <h5 class="w-185">Payment method</h5>
              <h5 class="w-12">Fee</h5>
            </div>

            <hr class="mb-6" />

            <div class="grid gap-y-3" v-if="product.payNowOptions.includes('CARDS') && cardEnabled">
              <div class="flex items-center justify-between" :key="index" v-for="(card, index) in product.payNowPaymentMethods">
                <div class="flex items-center gap-x-4 w-200">
                  <img v-if="card.title.toLowerCase() == 'visa'" :src="visaCard" alt="visa" />
                  <img v-else-if="card.title.toLowerCase() == 'mastercard'" :src="masterCard" alt="masterCard" />
                  <img v-else-if="card.title.toLowerCase() == 'amex'" :src="amexCard" alt="amex" />
                  <img v-else-if="card.title.toLowerCase() == 'diners'" :src="dinersClubCard" alt="diners-club" />
                  <img v-else-if="card.title.toLowerCase() == 'china unionpay'" :src="unionPayCard" alt="unionPay" />
                  <p>{{ card.title }}</p>
                </div>
                <p>{{ `${addZeroes(card.surcharge)} %` }}</p>
              </div>
            </div>

            <div class="grid mt-3" v-if="product.payNowOptions.includes('EFT') && product.eftEnabled">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-x-4">
                  <img :src="payId" alt="payId" height="20" width="50" />
                  <p>EFT via {{appName}} virtual account</p>
                </div>
                <p class="w-12">Free</p>
              </div>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')">
      <vs-col vs-w="12" class="flex items-center ml-4">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <img class="icon-control" :src="productLight" alt="product">
        </div>
        <h5 class="ml-5 mb-0">Finance options</h5>
      </vs-col>
      <hr class="line-hr mb-8 mt-2 w-full ml-4">
      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt">
          <vs-col v-for="(payLaterPlan, key) in product.payLaterProducts" :key="key" class="w-auto">
            <div class="radio-card" v-if="payLaterPlan.isChecked">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">{{ payLaterPlan.productName }}</vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center">
                      <p class="text-xs">Service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(payLaterPlan.msf)) }} %</h6>
                    </span>
                    <span class="flex items-center" v-if="payLaterPlan.feeModel == 'MSF_INCLUDED'">
                      <p class="text-xs">(payable by Merchant)</p>
                    </span>
                    <span class="flex items-center" v-else>
                      <p class="text-xs">(payable by Customer)</p>
                    </span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
const productLight = require("@/assets/images/product-grey.svg");
const payId = require("@/assets/images/cards/payid.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const unionPayCard = require("@/assets/images/cards/unionpay.svg");
const visaCard = require("@/assets/images/cards/visa.svg");

export default {
  name: "ProductSelection",
  components: {
    S3ImageGenerator,
  },
  props: {
    product: { type: Object },
  },
  data(){
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      productLight: productLight,
      payId: payId,
      cardEnabled: false,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      directDebitCard: directDebitCard,
      masterCard: masterCard,
      unionPayCard: unionPayCard,
      visaCard: visaCard,
      loadPayments: false,
    }
  },
  methods: {
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },

  mounted() {
    this.cardEnabled = this.product.payNowPaymentMethods.some((item) => item.isChecked);

    if (!this.product.payNowOptions && this.product.productOption.includes("pay-now")) {
      this.product.payNowOptions = ["CARDS", "EFT"];
      this.product.eftEnabled = true;
    }

    this.loadPayments = true;
  }
};
</script>